import React from "react";
import styled, { css, keyframes } from "styled-components";

import SplitHero from "../../components/split-hero";
import Text from "../../components/text";

import reactLogo from "../../images/react-logo.svg";
import reduxLogo from "../../images/redux-logo.svg";
import apolloLogo from "../../images/apollo-logo.svg";
import storybookLogo from "../../images/storybook-logo.svg";

const ReactRotate = keyframes`
    to {
      transform: rotate(-360deg);
    }
`;

const PivotRotate = keyframes`
    to {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
`;

const StorybookAnimation = keyframes`
    to {
      transform: translate(-150px, -150px) rotate(-360deg);
    }
`;

const ReduxAnimation = keyframes`
    to {
      transform: translate(150px, 150px) rotate(-360deg);
    }
`;

const ApolloAnimation = keyframes`
    to {
      transform: translate(-150px, 150px) rotate(-360deg);
    }
`;

const StyledAnimation = keyframes`
    to {
    transform: translate(150px, -150px) rotate(-360deg);
    }
`;

export const Animation = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

Animation.React = styled.div`
  border-radius: 100%;
  color: ${({ theme }) => theme.palette.text.light};
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
`;

Animation.Pivot = styled.div`
  align-items: center;
  animation: ${PivotRotate} 120s linear both infinite;
  display: flex;
  height: 0;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 0;
`;

Animation.Package = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.light};
  font-size: 26px;
  font-weight: 600;
  display: none;
  flex: none;
  overflow: hidden;
  justify-content: center;
  max-width: 200px;

  ${({ storybook, redux, apollo, styledComponents }) => {
    if (storybook) {
      return css`
        animation: ${StorybookAnimation} 120s linear both infinite;
        transform: translate(-150px, -150px);
      `;
    }
    if (redux) {
      return css`
        animation: ${ReduxAnimation} 120s linear both infinite;
        transform: translate(150px, 150px);
      `;
    }
    if (apollo) {
      return css`
        animation: ${ApolloAnimation} 120s linear both infinite;
        transform: translate(-150px, 150px);
      `;
    }
    if (styledComponents) {
      return css`
        animation: ${StyledAnimation} 120s linear both infinite;
        transform: translate(150px, -150px);
        font-size: 20px;
      `;
    }
  }}

  @media (min-width: 1140px) {
    display: flex;
  }
`;

Animation.Img = styled.img`
  height: 58px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

Animation.ImgLarge = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  animation: ${ReactRotate} 120s linear both infinite;
  height: 90px;

  @media (min-width: 1140px) {
    height: 128px;
  }
`;

Animation.Emoji = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  font-size: 48px;
`;

function ReactSection() {
  return (
    <SplitHero>
      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Animation>
            <Animation.React>
              <Animation.ImgLarge src={reactLogo} alt="React Logo" />
              React
            </Animation.React>
            <Animation.Pivot>
              <Animation.Package storybook>
                <Animation.Img src={storybookLogo} alt="Storybook Logo" />
                Storybook
              </Animation.Package>
            </Animation.Pivot>
            <Animation.Pivot>
              <Animation.Package redux>
                <Animation.Img src={reduxLogo} alt="Redux Logo" />
                Redux
              </Animation.Package>
            </Animation.Pivot>
            <Animation.Pivot>
              <Animation.Package apollo>
                <Animation.Img src={apolloLogo} alt="Apollo Logo" />
                Apollo
              </Animation.Package>
            </Animation.Pivot>
            <Animation.Pivot>
              <Animation.Package styledComponents>
                <Animation.Emoji>
                  <span role="img" aria-label="Nail Polish Emoji">
                    💅
                  </span>
                </Animation.Emoji>
                Styled Components
              </Animation.Package>
            </Animation.Pivot>
          </Animation>
        </SplitHero.Media.Content>
      </SplitHero.Media>

      <SplitHero.Content>
        <Text variant="h3" component="h2" shade="light">
          React.
        </Text>

        <Text variant="p" shade="mid">
          I have 4 years experience with React, building websites, applications,
          and component libraries. I have used both Redux and Apollo for client
          side state management. I also have professional experience with other
          packages that aid React development including: React Router, Styled
          Components, Storybook, Enzyme and React Testing Library.
        </Text>
      </SplitHero.Content>
    </SplitHero>
  );
}

export default ReactSection;
